@import "../defaultV2-theme/variables.scss";

.sv-dragged-element-shortcut {
  height: calcSize(3);
  min-width: calcSize(12.5);
  border-radius: calcSize(4.5);
  background-color: $background;
  padding: calcSize(2);
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-family: $font-family;
  font-size: calcFontSize(1);
  padding-left: calcSize(2.5);
  line-height: calcLineHeight(1.5);
}

.sv-matrixdynamic__drag-icon {
  padding-top: calcSize(1.75);
}

.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: calcSize(0.75);
  width: calcSize(2.5);
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: calcSize(1.25);
  cursor: move;
  margin-top: calcSize(1.5);
}

.sv-matrix-row--drag-drop-ghost-mod td {
  background-color: $background-dim;
}

.sv-matrix-row--drag-drop-ghost-mod td>* {
  visibility: hidden;
}