﻿[dir="rtl"] .sv_main, [style*="direction:rtl"] .sv_main {

  input {
    text-align: right;
  }
  th {
    text-align: right;
  }

  .sv_container {

    .sv_body {

      .sv_nav {

        .sv_start_btn,
        .sv_next_btn,
        .sv_complete_btn {
          float: left;
        }
        .sv_prev_btn {
          float: right;
        }
      }
    }
  }
}
