$header-background-color: #e7e7e7;
$body-container-background-color: #f4f4f4;

:root {
  --sjs-default-font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
$font-family: var(--font-family, var(--sjs-default-font-family));

$main-color: #1ab394;
$main-hover-color: #9f9f9f;
$body-background-color: white;
$inputs-background-color: $body-background-color;
$text-color: #404040;
$progress-text-color: #9d9d9d;
$text-border-color: #d4d4d4;
$border-color: rgba($text-color, 0.5);
$radio-checked-color: $text-color;
$error-color: #d52901;
$disable-color: #dbdbdb;
$error-background-color: rgba($error-color, 0.2);
$answer-background-color: rgba($main-color, 0.2);
$font-size: 16px;
$add-button-color: #1948b3;
$clean-button-color: $add-button-color;
$remove-button-color: #ff1800;
$base-line-height: 2em;
$disabled-text-color: rgba(64, 64, 64, 0.5);
$disabled-label-color: rgba(64, 64, 64, 0.5);
$slider-color: #fff;
$disabled-slider-color: #cfcfcf;
$progress-buttons-color: #8dd9ca;
$foreground-light: #909090;
