:root {
  --sjs-default-font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv_progress-buttons__container-center {
  text-align: center;
}

.sv_progress-buttons__container {
  display: inline-block;
  font-size: 0;
  width: 100%;
  max-width: 1100px;
  white-space: nowrap;
  overflow: hidden;
}

.sv_progress-buttons__image-button-left {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iMTEsMTIgOSwxNCAzLDggOSwyIDExLDQgNyw4ICIvPg0KPC9zdmc+DQo=);
}

.sv_progress-buttons__image-button-right {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iNSw0IDcsMiAxMyw4IDcsMTQgNSwxMiA5LDggIi8+DQo8L3N2Zz4NCg==);
}

.sv_progress-buttons__image-button--hidden {
  visibility: hidden;
}

.sv_progress-buttons__list-container {
  max-width: calc(100% - 36px);
  display: inline-block;
  overflow: hidden;
}

.sv_progress-buttons__list {
  display: inline-block;
  width: max-content;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.sv_progress-buttons__list li {
  width: 138px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  position: relative;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.sv_progress-buttons__list li:before {
  width: 24px;
  height: 24px;
  content: "";
  line-height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border: 3px solid;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
}

.sv_progress-buttons__list li:after {
  width: 73%;
  height: 3px;
  content: "";
  position: absolute;
  top: 15px;
  left: -36.5%;
}

.sv_progress-buttons__list li:first-child:after {
  content: none;
}

.sv_progress-buttons__list .sv_progress-buttons__page-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.sv_progress-buttons__list .sv_progress-buttons__page-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--nonclickable:before {
  cursor: not-allowed;
}

.sv_progress-toc {
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  max-width: 336px;
  height: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  min-width: calc(32 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc .sv-list__item.sv-list__item--selected .sv-list__item-body {
  background: rgba(25, 179, 148, 0.1);
  color: #161616;
  font-weight: 400;
}
.sv_progress-toc .sv-list__item span {
  white-space: break-spaces;
}
.sv_progress-toc .sv-list__item-body {
  padding-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-end: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-corner-radius, 4px);
  padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv_progress-toc--left {
  border-right: 1px solid #D6D6D6;
}

.sv_progress-toc--right {
  border-left: 1px solid #D6D6D6;
}

.sv_progress-toc--mobile {
  position: fixed;
  top: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: auto;
  min-width: auto;
  height: auto;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  z-index: 15;
  border-radius: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc--mobile > div {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc--mobile:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: calc(1.5 * (var(--sjs-corner-radius, 4px)));
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  z-index: 100;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: var(--sjs-corner-radius, 4px) var(--sjs-corner-radius, 4px) 0 0;
  display: flex;
}

.sv_window_content {
  padding: 0;
  margin: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  font-style: normal;
  color: black;
}

.sv_window_button .sv-svg-icon {
  margin: 4px;
}

sv-brand-info, .sv-brand-info {
  z-index: 1;
  position: relative;
  margin-top: 1px;
}

.sv-brand-info {
  width: 100%;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  text-align: center;
  color: #161616;
  background: white;
  padding: 32px 0;
  box-shadow: 0px -1px 0px #D6D6D6;
}
.sv-brand-info a {
  color: #161616;
  text-decoration-line: underline;
}

.sv-brand-info__text {
  font-weight: 600;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  color: #161616;
}

.sv-brand-info__logo {
  display: inline-block;
}
.sv-brand-info__logo img {
  width: 118px;
}

.sv-brand-info__terms {
  font-weight: 400;
  font-size: calc(0.75 * (var(--sjs-font-size, 16px)));
  line-height: var(--sjs-font-size, 16px);
}
.sv-brand-info__terms a {
  color: #909090;
}

.sv-dragged-element-shortcut {
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-width: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  padding-left: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
}

.sv-matrixdynamic__drag-icon {
  padding-top: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: calc(1.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: move;
  margin-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-matrix-row--drag-drop-ghost-mod td {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-matrix-row--drag-drop-ghost-mod td > * {
  visibility: hidden;
}

.sv-save-data_root {
  position: fixed;
  left: 50%;
  bottom: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  opacity: 0;
  padding: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  border-radius: calc(2 * (var(--sjs-corner-radius, 4px)));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  min-width: calc(30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  text-align: center;
  z-index: 1600;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%) translateY(calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  transition-timing-function: ease-in;
  transition-property: transform, opacity;
  transition-delay: 0.25s;
  transition: 0.5s;
}

.sv-save-data_root.sv-save-data_root--shown {
  transition-timing-function: ease-out;
  transition-property: transform, opacity;
  transform: translateX(-50%) translateY(0);
  transition-delay: 0.25s;
  opacity: 0.75;
}

.sv-save-data_root span {
  display: flex;
  flex-grow: 1;
}
.sv-save-data_root .sv-action-bar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.sv-save-data_root--shown.sv-save-data_success,
.sv-save-data_root--shown.sv-save-data_error {
  opacity: 1;
}

.sv-save-data_root.sv-save-data_root--with-buttons {
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-save-data_root.sv-save-data_error {
  background-color: var(--sjs-special-red, var(--red, #e60a3e));
  color: var(--sjs-general-backcolor, var(--background, #fff));
  font-weight: 600;
  gap: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button {
  font-weight: 600;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: #ffffff;
  background-color: var(--sjs-special-red, var(--red, #e60a3e));
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid #ffffff;
  border-radius: calc(1.5 * (var(--sjs-corner-radius, 4px)));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  align-items: center;
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button:hover,
.sv-save-data_root.sv-save-data_error .sv-save-data_button:focus {
  color: var(--sjs-special-red, var(--red, #e60a3e));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}

.sv-save-data_root.sv-save-data_success {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: #ffffff;
  font-weight: 600;
}

body {
  --sv-default-mark: true;
}

.sv_main.sv_frame .sv_container {
  max-width: 80%;
  margin: auto;
  padding: 0 1em;
}
.sv_main.sv_frame .sv_container .sv_header {
  padding-top: 5em;
  padding-bottom: 1em;
}

.sv_main {
  --sv-mobile-width: 600px;
  background-color: var(--body-container-background-color, #f4f4f4);
}
.sv_main hr {
  border-bottom: 1px solid var(--border-color, #e7e7e7);
}
.sv_main input[type=button],
.sv_main button {
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
}
.sv_main input[type=button]:hover,
.sv_main button:hover {
  background-color: var(--main-hover-color, #179d82);
}
.sv_main .sv_q_other input,
.sv_main .sv_q_text_root,
.sv_main .sv_q_dropdown_control,
.sv_main input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main select,
.sv_main textarea {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--border-color, #e7e7e7);
  color: var(--text-color, #6d7072);
  background-color: var(--inputs-background-color, white);
  opacity: 1;
}
.sv_main .sv_q_other input:focus,
.sv_main .sv_q_text_root:focus,
.sv_main .sv_q_dropdown_control:focus,
.sv_main input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border: 1px solid var(--main-color, #1ab394);
}
.sv_main .sv_q_dropdown_control {
  display: flex;
  justify-content: space-between;
  padding-inline-end: 1em;
}
.sv_main .sv_q_dropdown__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  position: relative;
}
.sv_main .sv_q_dropdown__hint-prefix {
  opacity: 0.5;
}
.sv_main .sv_q_dropdown__hint-prefix span {
  word-break: unset;
}
.sv_main .sv_q_dropdown__hint-suffix {
  display: flex;
  opacity: 0.5;
}
.sv_main .sv_q_dropdown__hint-suffix span {
  word-break: unset;
}
.sv_main .sv_q_dropdown_control__input-field-component {
  height: auto;
}
.sv_main .sv_q_dropdown_clean-button {
  margin: auto 2em;
}
.sv_main .sv_q_dropdown_clean-button-svg {
  width: 1em;
  height: 1em;
}
.sv_main .sv_q_dropdown__filter-string-input {
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  width: auto;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  background-color: transparent;
}
.sv_main .sv_q_tagbox__filter-string-input {
  position: initial;
}
.sv_main .sv_q_tagbox__placeholder {
  position: absolute;
  top: 0;
  left: 1em;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
}
.sv_main .sv_q_input.sv_q_input.sv_q_input.sv_q_tagbox.sv_q_tagbox.sv_q_tagbox {
  height: auto;
}
.sv_main .sv_q_tagbox__value.sv_q_dropdown__value {
  display: flex;
  flex-wrap: wrap;
  padding-inline: unset;
  margin-inline: unset;
  margin-block: unset;
  gap: 4px;
}
.sv_main .sv-tagbox__item {
  position: relative;
  display: flex;
  border-radius: 2px;
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
}
.sv_main .sv_q_tagbox-item_clean-button-svg {
  fill: var(--body-background-color, white);
  width: 16px;
  height: 16px;
}
.sv_main .sv_select_wrapper {
  background-color: var(--body-background-color, white);
}
.sv_main .sv_select_wrapper:before {
  background-color: var(--main-color, #1ab394);
}
.sv_main .sv_header {
  color: var(--header-color, #6d7072);
}
.sv_main .sv_custom_header {
  background-color: var(--header-background-color, #e7e7e7);
}
.sv_main .sv_container {
  color: var(--text-color, #6d7072);
}
.sv_main .sv_body {
  background-color: var(--body-background-color, white);
  border-top: 2px solid var(--main-color, #1ab394);
}
.sv_main .sv_progress {
  background-color: var(--border-color, #e7e7e7);
  margin-bottom: 2em;
}
.sv_main .sv_progress_bar {
  background-color: var(--main-color, #1ab394);
}
.sv_main .sv_p_root > .sv_row {
  border-bottom: 1px solid var(--border-color, #e7e7e7);
}
.sv_main .sv_p_root > .sv_row:nth-child(odd) {
  background-color: var(--body-background-color, white);
}
.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: var(--body-container-background-color, #f4f4f4);
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid var(--error-color, #ed5565);
  background-color: var(--error-background-color, #fcdfe2);
  color: var(--error-color, #ed5565);
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + input, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + textarea, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + .sv_select_wrapper select {
  border: 1px solid var(--error-color, #ed5565);
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + input:focus, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + textarea:focus, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + .sv_select_wrapper select:focus {
  outline: var(--error-color, #ed5565) auto 5px;
}
.sv_main table.sv_q_matrix tr,
.sv_main table.sv_q_matrix_dropdown tr,
.sv_main table.sv_q_matrix_dynamic tr {
  border-bottom: 1px solid var(--border-color, #e7e7e7);
}
.sv_main .sv_matrix_cell {
  vertical-align: top;
}
.sv_main .sv_matrix_cell_detail {
  vertical-align: middle;
  text-align: center;
  width: 32px;
}
.sv_main .sv-table__cell--choice {
  text-align: center;
}
.sv_main .sv_matrix_cell_detail_button {
  width: 29px;
  height: 29px;
  min-width: 0px;
  padding: 0;
  outline: none;
}
.sv_main .sv_matrix_cell_detail_button_expanded {
  background-color: lightgray;
}
.sv_main .sv_matrix_cell_detail_button_expanded:hover {
  background-color: darkgray;
}
.sv_main .sv_matrix_cell_detail_rowtext {
  vertical-align: middle;
}
.sv_main .sv_q_m_cell_selected {
  color: var(--body-background-color, white);
  background-color: var(--main-hover-color, #179d82);
}
.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--main-hover-color, #179d82);
  border-color: var(--main-hover-color, #179d82);
  color: var(--body-background-color, white);
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  border: 1px solid var(--border-color, #e7e7e7);
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid var(--main-hover-color, #179d82);
}
.sv_main .sv_q_rating__item-star > svg {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  fill: var(--text-color, #6d7072);
}
.sv_main .sv_q_rating__item-star > svg.sv-star-2 {
  display: none;
}
.sv_main .sv_q_rating__item-star > svg:hover {
  border: 1px solid var(--main-hover-color, #179d82);
}
.sv_main .sv_q_rating__item-star--selected > svg {
  fill: var(--main-color, #1ab394);
}
.sv_main .sv_q_rating__item-smiley > svg {
  height: 24px;
  width: 24px;
  padding: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid var(--border-color, #e7e7e7);
  fill: var(--text-color, #6d7072);
}
.sv_main .sv_q_rating__item-smiley > svg > use {
  display: block;
}
.sv_main .sv_q_rating__item-smiley > svg:hover {
  border: 1px solid var(--main-hover-color, #179d82);
}
.sv_main .sv_q_rating__item-smiley--selected > svg {
  background-color: var(--main-hover-color, #179d82);
  fill: var(--body-background-color, white);
}
.sv_main .sv_q_imgsel.checked label > div {
  background-color: var(--main-color, #1ab394);
}
.sv_main .sv_q_file_remove:hover {
  color: var(--main-color, #1ab394);
}
.sv_main .sv-boolean__switch {
  background-color: var(--main-color, #1ab394);
  outline-color: var(--main-color, #1ab394);
}
.sv_main .sv-boolean__slider {
  background-color: var(--slider-color, white);
}
.sv_main .sv-boolean__label--disabled {
  color: var(--label-disabled-color, rgba(64, 64, 64, 0.5));
}

.sv_main {
  width: 100%;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--font-size, 14px);
}
.sv_main hr {
  border: none;
}
.sv_main input[type=button],
.sv_main button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 0.85em;
  font-weight: bold;
  line-height: var(--base-line-height, 2em);
  border: none;
  min-width: 100px;
  cursor: pointer;
  padding: 0.3em 2em;
  border-radius: 2px;
}
.sv_main .sv_custom_header {
  width: 100%;
  height: var(--header-height, 74%);
  max-height: 275px;
}
.sv_main .sv_container {
  position: relative;
}
.sv_main .sv_header {
  padding: 1em;
}
.sv_main .sv_header h3 {
  font-size: 2em;
  font-weight: 300;
  margin: 0;
}
.sv_main .sv_body.sv_completed_page {
  text-align: center;
  padding: 5em 1em;
}
.sv_main .sv_body.sv_completed_page > h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 300;
}
.sv_main .sv_body {
  padding: 1em 1em 1.6428em 1em;
}
.sv_main .sv_progress {
  height: 0.3em;
}
.sv_main .sv_progress .sv_progress_bar {
  height: 100%;
  min-width: 100px;
  position: relative;
  margin-top: 2em;
}
.sv_main .sv_progress .sv_progress_bar > span {
  position: absolute;
  top: -1.5em;
  display: inline-block;
  white-space: nowrap;
}
.sv_main .sv_p_root .sv_page_title {
  font-size: 1em;
  margin-top: 0.1em;
  font-weight: normal;
}
.sv_main .sv_p_root .sv_p_title {
  font-weight: bold;
  font-size: 1.15em;
  margin: 1.5em 0 0 0;
}
.sv_main .sv_p_root .sv_p_panel {
  margin: 0 0 1.5em 0;
}
.sv_main .sv_p_root > .sv_row {
  padding: 0;
}
.sv_main .sv_p_root .sv_q_title {
  font-weight: bold;
  font-size: 1em;
  margin: 0.5em 0;
}
.sv_main .sv_p_root .sv_q--disabled .sv_q_title {
  color: var(--label-disabled-color, rgba(64, 64, 64, 0.5));
}
.sv_main .sv_p_root .sv_q--disabled .sv_q_file .sv_q_file_choose_button {
  display: none;
}
.sv_main .sv_p_root .sv_q_description {
  margin-top: -0.6em;
  min-height: 0.6em;
}
.sv_main .sv_p_root .sv_q_flow .sv_q_checkbox_inline,
.sv_main .sv_p_root .sv_q_flow .sv_q_radiogroup_inline,
.sv_main .sv_p_root .sv_q_flow .sv_q_imagepicker_inline {
  line-height: var(--base-line-height, 2em);
  display: inline-block;
}
.sv_main .sv_p_root .sv_q {
  padding: 0.5em var(--sv-element-add-padding-right, 1em) 1.5em var(--sv-element-add-padding-left, 1em);
  box-sizing: border-box;
  overflow: auto;
}
.sv_main .sv_p_root .sv_q .sv_panel_dynamic .sv_q_title {
  font-weight: normal;
}
.sv_main .sv_p_root .sv_q .sv_panel_dynamic .sv-paneldynamic__progress-container {
  position: relative;
  display: inline-block;
  width: calc(100% - 250px);
  margin-left: 40px;
  margin-top: 10px;
}
.sv_main .sv_p_root .sv_q .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}
.sv_main .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_p_root .sv_q .sv_q_text_root,
.sv_main .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main .sv_p_root .sv_q select,
.sv_main .sv_p_root .sv_q textarea {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  line-height: var(--base-line-height, 2em);
  padding-inline-start: 1em;
}
.sv_main .sv_p_root .sv_q .sv_q_other input:focus,
.sv_main .sv_p_root .sv_q .sv_q_text_root:focus,
.sv_main .sv_p_root .sv_q .sv_q_dropdown_control:focus,
.sv_main .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus,
.sv_main .sv_p_root .sv_q select:focus,
.sv_main .sv_p_root .sv_q textarea:focus {
  outline: none;
}
.sv_main .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main .sv_p_root .sv_q select {
  height: calc(2em + 1px);
}
.sv_main .sv_p_root .sv_q .sv_q_dropdown_control__input-field-component {
  height: auto;
}
.sv_main .sv_p_root .sv_q div.sv_q_text_root,
.sv_main .sv_p_root .sv_q div.sv_q_dropdown_control {
  min-height: 2em;
  min-width: 7em;
}
.sv_main .sv_p_root .sv_q .sv_select_wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}
.sv_main .sv_p_root .sv_q .sv_select_wrapper select {
  display: block;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.5em;
}
.sv_main .sv_p_root .sv_q .sv_select_wrapper select::-ms-expand {
  display: none;
}
.sv_main .sv_p_root .sv_q .sv_select_wrapper:before {
  padding: 1em;
  position: absolute;
  inset-inline-end: 0;
  top: 1px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCXZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCQkuc3QwIHsNCgkJCWZpbGw6ICNGRkZGRkY7DQoJCX0NCgk8L3N0eWxlPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTIsMTYgMTQsMTQgMTcsMTcgMjAsMTQgMjIsMTYgMTcsMjEgIiAvPg0KPC9zdmc+");
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  pointer-events: none;
}
.sv_main .sv_p_root .sv_q .sv_select_wrapper.sv_q_tagbox_wrapper::before {
  height: 100%;
  padding: 0 1em;
}
.sv_main .sv_p_root .sv_q input[type=color] {
  min-height: var(--base-line-height, 2em);
  padding: 0;
}
.sv_main .sv_p_root .sv_q input[type=radio],
.sv_main .sv_p_root .sv_q input[type=checkbox] {
  margin: 0;
  margin-right: 0.55em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin-top: -0.1em;
}
.sv_main .sv_p_root .sv_q input[type=radio]:focus,
.sv_main .sv_p_root .sv_q input[type=checkbox]:focus {
  outline: 1px dotted var(--main-color, #1ab394);
}
.sv_main .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
.sv_main .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child),
.sv_main .sv_p_root .sv_q .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
  margin-right: 1.5em;
}
.sv_main .sv_p_root .sv_q .sv_q_imgsel label > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.sv_main .sv_p_root .sv_q .sv_q_imgsel label > div > img {
  display: block;
  pointer-events: none;
  position: relative;
}
.sv_main .sv_p_root .sv_q .sv_q_radiogroup_clear {
  margin-top: 0.5em;
}
.sv_main .sv_p_root .sv_q .sv_q_checkbox_inline,
.sv_main .sv_p_root .sv_q .sv_q_radiogroup_inline,
.sv_main .sv_p_root .sv_q .sv_q_imagepicker_inline {
  line-height: var(--base-line-height, 2em);
  display: inline-block;
}
.sv_main .sv_p_root .sv_q .sv_q_footer {
  padding: 1em 0;
}
.sv_main .sv_p_root table {
  width: 100%;
  border-collapse: collapse;
}
.sv_main .sv_p_root table.sv_q_matrix .sv_matrix_dynamic_button, .sv_main .sv_p_root table.sv_q_matrix_dropdown .sv_matrix_dynamic_button, .sv_main .sv_p_root table.sv_q_matrix_dynamic .sv_matrix_dynamic_button {
  padding: 0.3em 2em;
}
.sv_main .sv_p_root table.sv_q_matrix td,
.sv_main .sv_p_root table.sv_q_matrix th {
  padding: 0.5em 1em;
  text-align: center;
}
@media (min-width: 768px) {
  .sv_main .sv_p_root table.sv_q_matrix td {
    min-width: 10em;
  }
}
.sv_main .sv_p_root table.sv_q_matrix td .sv_q_m_label {
  position: static;
  display: block;
  width: 100%;
}
.sv_main .sv_p_root table.sv_q_matrix td:first-child {
  text-align: left;
}
.sv_main .sv_p_root table.sv_q_matrix_dropdown .sv_qcbc .sv_q_checkbox_control_label {
  margin-right: 1.5em;
}
.sv_main .sv_p_root table td,
.sv_main .sv_p_root table th {
  padding: 0 1em;
}
.sv_main .sv_p_root table td {
  padding: 0.5em;
}
.sv_main .sv_p_root table th {
  line-height: 1.3em;
  padding: 0.5em;
  vertical-align: bottom;
  font-weight: bold;
}
.sv_main .sv_p_root fieldset.sv_qcbc {
  line-height: var(--base-line-height, 2em);
  padding-top: 0.1em;
}
.sv_main .sv_p_root .sv_q_checkbox_label,
.sv_main .sv_p_root .sv_q_radiogroup_label {
  display: block;
}
.sv_main .sv_p_root .sv_q_other {
  margin-left: 1em;
}
.sv_main .sv_p_root .sv_q_select_column {
  display: inline-block;
  vertical-align: top;
  min-width: 10%;
}
.sv_main .sv_p_root .sv_q_rating {
  line-height: var(--base-line-height, 2em);
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_item > * {
  word-spacing: initial;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sv_main .sv_p_root .sv_q_rating .sv_q_rating_item > * {
    word-spacing: normal;
  }
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
  display: inline-block;
  min-width: var(--base-line-height, 2em);
  padding: 0 0.3em;
  margin-left: -1px;
  text-align: center;
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_item:not(:nth-child(2)) .sv_q_rating_item_text:not(:hover) {
  border-left-color: transparent;
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_min_text {
  margin-right: 0.5em;
}
.sv_main .sv_p_root .sv_q_rating .sv_q_rating_max_text {
  margin-left: 0.5em;
}
.sv_main .sv_nav {
  display: block;
  padding-top: 1em;
  min-height: var(--base-line-height, 2em);
}
.sv_main .sv_nav .sv_nav_btn {
  float: right;
  margin: 0 16px;
}
.sv_main .sv_nav .sv-action {
  display: block;
}
.sv_main .sv_nav .sv-action:not(:last-child) .sv-action__content {
  padding: 0;
}
.sv_main .sv_nav .sv-action__content {
  display: block;
}
.sv_main .sv_nav .sv_start_btn,
.sv_main .sv_nav .sv_next_btn,
.sv_main .sv_nav .sv_complete_btn {
  float: right;
}
.sv_main .sv_nav .sv_preview_btn {
  float: right;
}
.sv_main .sv_nav .sv_prev_btn {
  float: left;
}
.sv_main .sv_q_image {
  display: inline-block;
}

.sv_main.sv_main .sv-action-bar-item.sv_edit_btn {
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
  float: left;
  margin-bottom: 7px;
}

.sv_main.sv_main .sv-action-bar-item.sv_edit_btn:hover {
  background-color: var(--main-hover-color, #179d82);
}

.sv_main .sv_p_root .sv_row .sv_q.sv_qstn:first-child:last-child {
  flex: none !important;
}

.sv_q_dropdown_control input[readonly] {
  pointer-events: none;
}

[dir=rtl] .sv_main input, [style*="direction:rtl"] .sv_main input {
  text-align: right;
}
[dir=rtl] .sv_main th, [style*="direction:rtl"] .sv_main th {
  text-align: right;
}
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn, [style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
[style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
[style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  float: left;
}
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn, [style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  float: right;
}

.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
  display: block;
  width: 100% !important;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .title-left,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .title-left {
  float: none;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_radiogroup_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_checkbox_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_imagepicker_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_radiogroup_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_checkbox_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_imagepicker_inline {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic thead {
  display: none;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td.sv-table__cell--choice {
  text-align: initial;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td:before {
  content: attr(data-responsive-title);
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td label.sv_q_m_label,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td label.sv_q_m_label {
  display: inline;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td:after,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td:after {
  content: attr(data-responsive-title);
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix .sv_q_m_cell,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix .sv_q_m_cell {
  text-align: initial;
}

@media (max-width: 600px) {
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    display: block;
    width: 100% !important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .title-left,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .title-left {
    float: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_radiogroup_inline,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_checkbox_inline,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_imagepicker_inline,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_radiogroup_inline,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_checkbox_inline,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_imagepicker_inline {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix thead,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown thead,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic thead,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix thead,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown thead,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic thead {
    display: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td.sv-table__cell--choice,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td.sv-table__cell--choice,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td.sv-table__cell--choice,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td.sv-table__cell--choice,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td.sv-table__cell--choice,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td.sv-table__cell--choice {
    text-align: initial;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tbody,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tr,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td:before,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td:before,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td:before,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td:before {
    content: attr(data-responsive-title);
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td label.sv_q_m_label,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td label.sv_q_m_label {
    display: inline;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td:after,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td:after {
    content: attr(data-responsive-title);
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix .sv_q_m_cell,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix .sv_q_m_cell {
    text-align: initial;
  }
}
.sv_main .sv_qstn .sv_q_file .sv-visuallyhidden {
  position: absolute !important;
  opacity: 0;
}
.sv_main .sv_qstn .sv_q_file .sv_q_file_choose_button {
  display: inline-block;
  box-sizing: border-box;
  min-width: 100px;
  line-height: 2em;
  padding: 0.25em 2em;
  font-size: 0.85em;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.sv_main .sv_qstn .sv_q_file .sv_q_file_remove_button {
  padding: 0.25em 2em;
}
.sv_main .sv_qstn .sv_q_file .sv-file__decorator {
  display: inline-block;
  min-width: 250px;
}
.sv_main .sv_qstn .sv_q_file .sv-file__decorator .sv_q_file_placeholder {
  margin-left: 1em;
  display: inline-block;
}

.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.sjs_sp_container {
  position: relative;
  max-width: 100%;
}

.sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sjs_sp_controls > button {
  user-select: none;
}

.sjs_sp_container > div > canvas:focus {
  outline: none;
}

.sjs_sp_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.sjs_sp_canvas {
  position: relative;
  max-width: 100%;
  display: block;
}

.sjs_sp__background-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

:root {
  --sjs-default-font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-boolean__decorator {
  border-radius: 2px;
}

.sv_main .sv-boolean__decorator + .sv-boolean__label {
  float: none;
  vertical-align: top;
  margin-left: 0.5em;
}

.sv-boolean__svg {
  border: none;
  border-radius: 2px;
  background-color: #1ab394;
  fill: white;
  width: 24px;
  height: 24px;
}

.sv-boolean--allowhover:hover .sv-boolean__checked-path {
  display: inline-block;
}
.sv-boolean--allowhover:hover .sv-boolean__svg {
  background-color: #9f9f9f;
  fill: white;
}
.sv-boolean--allowhover:hover .sv-boolean__unchecked-path,
.sv-boolean--allowhover:hover .sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean__checked-path,
.sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #1ab394;
}
.sv-boolean--indeterminate .sv-boolean__indeterminate-path {
  display: inline-block;
}

.sv-boolean--indeterminate .sv-boolean__unchecked-path,
.sv-boolean--checked .sv-boolean__unchecked-path {
  display: none;
}

.sv-boolean--checked .sv-boolean__checked-path {
  display: inline-block;
}

.sv-boolean--disabled.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #dbdbdb;
}

.sv-boolean--disabled .sv-boolean__svg {
  background-color: #dbdbdb;
}

td.sv_matrix_cell .sv_qbln,
td.td.sv_matrix_cell .sv_qbln {
  text-align: center;
}
td.sv_matrix_cell .sv_qbln .sv-boolean,
td.td.sv_matrix_cell .sv_qbln .sv-boolean {
  text-align: initial;
}

sv-components-container,
.sd-components-container {
  display: flex;
}

.sv-components-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sv-components-column {
  display: flex;
  flex-direction: column;
}

.sv-components-column--expandable {
  flex-grow: 1;
}

.sv-components-row > .sv-components-column--expandable {
  width: 1px;
}

.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.sv_main.sv_default_css {
  background-color: var(--body-container-background-color, #f4f4f4);
}

.sv_main.sv_default_css hr {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css input[type=button],
.sv_main.sv_default_css button {
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css input[type=button]:hover,
.sv_main.sv_default_css button:hover {
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv_main.sv_default_css .sv_header {
  color: var(--header-color, #6d7072);
}

.sv_main.sv_default_css .sv_custom_header {
  background-color: var(--header-background-color, #e7e7e7);
}

.sv_main.sv_default_css .sv_container {
  color: var(--text-color, #404040);
}

.sv_main.sv_default_css .sv_body {
  background-color: var(--body-background-color, white);
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_progress {
  background-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css .sv_progress_bar {
  background-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_progress-buttons__list li:before {
  border-color: var(--progress-buttons-color, #8dd9ca);
  background-color: var(--progress-buttons-color, #8dd9ca);
}

.sv_main.sv_default_css .sv_progress-buttons__list li:after {
  background-color: var(--progress-buttons-line-color, #d4d4d4);
}

.sv_main.sv_default_css .sv_progress-buttons__list .sv_progress-buttons__page-title {
  color: var(--text-color, #404040);
}

.sv_main.sv_default_css .sv_progress-buttons__list .sv_progress-buttons__page-description {
  color: var(--text-color, #404040);
}

.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed:before {
  border-color: var(--main-color, #1ab394);
  background-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed + li:after {
  background-color: var(--progress-buttons-color, #8dd9ca);
}

.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, #1ab394);
  background-color: white;
}

.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, #1ab394);
  background-color: white;
}

.sv_main.sv_default_css .sv_p_root > .sv_row {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(odd) {
  background-color: var(--body-background-color, white);
}

.sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(even) {
  background-color: var(--body-container-background-color, #f4f4f4);
}

.sv_main.sv_default_css .sv_q_other input {
  color: var(--text-color, #404040);
  -webkit-text-fill-color: var(--text-color, #404040);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css .sv_q_text_root {
  color: var(--text-color, #404040);
  -webkit-text-fill-color: var(--text-color, #404040);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css .sv_q_dropdown_control {
  color: var(--text-input-color, #6d7072);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css .sv_q_dropdown_control__input-field-component {
  height: auto;
}

.sv_main.sv_default_css input[type=text] {
  color: var(--text-color, #404040);
  -webkit-text-fill-color: var(--text-color, #404040);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css select {
  color: var(--text-color, #404040);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css textarea {
  color: var(--text-input-color, #6d7072);
  -webkit-text-fill-color: var(--text-input-color, #6d7072);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]) {
  border: 1px solid var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
  color: var(--text-input-color, #6d7072);
  -webkit-text-fill-color: var(--text-input-color, #6d7072);
}

.sv_main.sv_default_css input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus {
  border: 1px solid var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper .sv_q_dropdown_control {
  background-color: var(--inputs-background-color, white);
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper .sv_q_dropdown_control:focus-within {
  border: 1px solid var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_q_other input:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_q_text_root:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_q_dropdown_control:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control .sv_q_dropdown__value .sv_q_dropdown__filter-string-input[type=text] {
  border: none;
  outline: none;
  padding: 0px;
  padding-top: 1px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  height: 100%;
  background-color: transparent;
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control .sv_q_dropdown__value.sv_q_tagbox__value .sv_q_dropdown__filter-string-input.sv_q_tagbox__filter-string-input {
  position: initial;
}

.sv_main.sv_default_css input[type=text]:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type=radio]:focus,
.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type=checkbox]:focus {
  outline: 1px dotted var(--main-color, #1ab394);
}

.sv_main.sv_default_css select:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css textarea:focus {
  border-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_q_input.sv_q_input.sv_q_input.sv_q_tagbox.sv_q_tagbox.sv_q_tagbox:not(.sv_q_tagbox--empty) {
  height: auto;
}

.sv_main.sv_default_css .sv_select_wrapper.sv_q_tagbox_wrapper::before {
  height: 100%;
  padding: 0 1em;
}

.sv_main.sv_default_css .sv_select_wrapper {
  background-color: var(--body-background-color, white);
}

.sv_main.sv_default_css .sv_select_wrapper::before {
  background-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--main-hover-color, #9f9f9f);
  border-color: var(--main-hover-color, #9f9f9f);
  color: var(--body-background-color, white);
}

.sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text:hover {
  border-color: var(--main-hover-color, #9f9f9f);
}

.sv_main.sv_default_css table.sv_q_matrix tr {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css table.sv_q_matrix_dropdown tr {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css table.sv_q_matrix_dynamic tr {
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main.sv_default_css .sv_q_m_cell_selected {
  color: var(--body-background-color, white);
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv_main .sv_q_file_remove:hover {
  color: var(--main-color, #1ab394);
}

.sv_main .sv_q_file_choose_button {
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
}

.sv_main .sv_q_file_choose_button:hover {
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv_main .sv_q_imgsel.checked label > div {
  background-color: var(--main-color, #1ab394);
}

.sv_main.sv_default_css .sv_p_description {
  padding-left: 1.29em;
}

.sv_main .sv-progress {
  background-color: var(--header-background-color, #e7e7e7);
}

.sv_main .sv-progress__bar {
  background-color: var(--main-color, #1ab394);
}

.sv_main .sv-paneldynamic__prev-btn.sv-paneldynamic__prev-btn--disabled,
.sv_main .sv-paneldynamic__next-btn.sv-paneldynamic__next-btn--disabled {
  fill: var(--disable-color, #dbdbdb);
}

.sv_main .sv-paneldynamic__progress-text {
  color: var(--progress-text-color, #9d9d9d);
}

.sv_main .sv-paneldynamic__prev-btn,
.sv_main .sv-paneldynamic__next-btn {
  fill: var(--text-color, #404040);
}

.sv_main .sv-boolean__switch {
  background-color: var(--main-color, #1ab394);
  outline-color: var(--main-color, #1ab394);
}

.sv_main .sv-boolean__slider {
  background-color: var(--slider-color, #fff);
}

.sv_main .sv-boolean__label--disabled {
  color: var(--disabled-label-color, rgba(64, 64, 64, 0.5));
}

.sv_main .sv-boolean--disabled .sv-boolean__switch {
  background-color: var(--disabled-switch-color, #9f9f9f);
}

.sv_main .sv-boolean--disabled .sv-boolean__slider {
  background-color: var(--disabled-slider-color, #cfcfcf);
}

.sv_main .sjs_sp_container {
  border: 1px dashed var(--disable-color, #dbdbdb);
}

.sv_main .sjs_sp_placeholder {
  color: var(--foreground-light, #909090);
}

.sv_main .sv_matrix_detail_row {
  background-color: #ededed;
  border-top: 1px solid var(--header-background-color, #e7e7e7);
  border-bottom: 1px solid var(--header-background-color, #e7e7e7);
}

.sv_main .sv-action-bar-item {
  color: var(--text-color, #404040);
}

.sv_main .sv-action-bar-item__icon use {
  fill: var(--foreground-light, #909090);
}

.sv_main .sv-action-bar-item:hover {
  background-color: var(--background-dim, #f3f3f3);
}

.sv_main .sv-button-group__item--hover:hover {
  background-color: var(--background-dim, #f3f3f3);
}

.sv_main .sv-button-group__item-icon use {
  fill: var(--foreground-light, #909090);
}

.sv_main .sv-button-group__item--selected {
  color: var(--main-color, #1ab394);
}

.sv_main .sv-button-group__item--selected .sv-button-group__item-icon use {
  fill: var(--main-color, #1ab394);
}

.sv_main .sv-button-group__item--disabled {
  color: var(--foreground-disabled, #161616);
}

.sv_main .sv-button-group__item--disabled .sv-button-group__item-icon use {
  fill: var(--foreground-disabled, #161616);
}

.sv_main .sv-button-group__item {
  background: var(--body-background-color, white);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv_main .sv_qstn textarea {
  max-width: 100%;
}

body .sv-list__input {
  color: var(--text-input-color, #6d7072);
  border-color: var(--border-color, rgba(64, 64, 64, 0.5));
  background-color: var(--inputs-background-color, white);
}

body .sv-list__input::placeholder {
  color: var(--foreground-light, #909090);
}

body .sv-list__input:disabled {
  color: var(--foreground-disabled, #161616);
}

body .sv-list__input:disabled::placeholder {
  color: var(--foreground-disabled, #161616);
}

body .sv-list__item--selected,
body .sv-list__item--selected:hover,
body .sv-list__item--selected.sv-list__item--focused,
body .sv-list__item--selected:focus {
  background-color: var(--main-color, #1ab394);
  color: var(--inputs-background-color, white);
  font-weight: 600;
}

body .sv-multi-select-list .sv-list__item--selected,
body .sv-multi-select-list .sv-list__item--selected:hover {
  background: rgba(25, 179, 148, 0.1);
  color: var(--foreground-disabled, #161616);
  font-weight: 400;
}

.sv-skeleton-element {
  background-color: var(--background-dim, #f3f3f3);
}

.sv_main .sv-ranking-item:focus .sv-ranking-item__index {
  border: 2px solid var(--main-color, #1ab394);
}

.sv_main .sv-ranking-item__icon {
  fill: var(--main-color, #1ab394);
}

body .sv-ranking-shortcut .sv-ranking-item__icon {
  fill: var(--main-color, #1ab394);
}

.sv_main span {
  word-break: break-word;
}

.sv_main legend {
  border: none;
  margin: 0;
}

.sv_row {
  display: flex;
  flex-wrap: wrap;
  clear: both;
  min-width: 300px;
}

.sv_row .sv_qstn {
  float: left;
}

.sv_row .sv_qstn:last-child {
  float: none;
}

.sv_qstn {
  vertical-align: top;
  overflow: auto;
  min-width: 300px;
}

.sv_p_container {
  vertical-align: top;
  min-width: 300px;
}

.sv_q_title .sv_question_icon {
  float: right;
  margin-right: 1em;
}

.sv_q_title .sv_question_icon::before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.5em;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMCAxMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgMTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM2RDcwNzI7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMiwyIDAsNCA1LDkgMTAsNCA4LDIgNSw1ICIvPg0KPC9zdmc+DQo=");
}

.sv_q_title .sv_question_icon.sv_expanded::before {
  transform: rotate(180deg);
}

.sv_qbln .checkbox-material {
  margin-right: 3px;
}

.sv_qcbx .checkbox-material {
  margin-right: 5px;
}

.sv_qcbx .checkbox label {
  justify-content: left;
  display: inline-block;
}

.sv_qstn .radio label {
  justify-content: left;
  display: inline-block;
}

.sv_qstn .sv_q_imgsel > label img {
  pointer-events: none;
}

.sv_qstn .sv_q_imgsel.sv_q_imagepicker_inline {
  display: inline-block;
}

.sv_qstn label.sv_q_m_label {
  position: absolute;
  margin: 0;
  display: block;
  width: 100%;
}

.sv_qstn td {
  position: relative;
}

.sv_q_mt {
  table-layout: fixed;
}

.sv_q_mt_label {
  display: flex;
  align-items: center;
  font-weight: inherit;
}

.sv_q_mt_title {
  margin-right: 0.5em;
  width: 33%;
}

.sv_q_mt_item {
  flex: 1;
}

.sv_q_mt_item_value {
  float: left;
}

[dir=rtl] .sv_q_mt_item_value {
  float: right;
}

.sv_qstn.sv_qstn_left {
  margin-top: 0.75em;
}

.sv_qstn .title-left {
  float: left;
  margin-right: 1em;
  max-width: 50%;
}

[dir=rtl] .sv_qstn .title-left {
  float: right;
  margin-left: 1em;
}

.sv_qstn .content-left {
  overflow: hidden;
}

.sv_q_radiogroup_inline .sv_q_radiogroup_other {
  display: inline-block;
}

.sv_q_checkbox_inline .sv_q_checkbox_other {
  display: inline-block;
}

.sv_q_checkbox_inline,
.sv_q_radiogroup_inline,
.sv_q_imagepicker_inline {
  line-height: 2.5em;
}

.form-inline .sv_q_checkbox_inline:not(:last-child) {
  margin-right: 1em;
}

.form-inline .sv_q_radiogroup_inline:not(:last-child) {
  margin-right: 1em;
}

.sv_imgsel .sv_q_imagepicker_inline:not(:last-child) {
  margin-right: 1em;
}

.sv_qstn fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.sv_qstn .sv_q_file_placeholder {
  display: none;
}

.sv_p_title {
  padding-left: 1em;
  padding-bottom: 0.3em;
}

.sv_p_title_expandable,
.sv_q_title_expandable {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.sv_p_title_expandable::after,
.sv_q_title_expandable::after {
  content: " ";
  display: block;
  background-image: url("data:image/svg+xml,<?xml version='1.0' encoding='utf-8'?><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'><style type='text/css'> .st0{fill:#404040;}</style><polygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.sv_p_title_expanded::after,
.sv_q_title_expanded::after {
  transform: rotate(180deg);
}

.sv_p_title .sv_panel_icon {
  float: right;
  margin-right: 1em;
}

.sv_p_title .sv_panel_icon::before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.5em;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMCAxMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgMTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM2RDcwNzI7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMiwyIDAsNCA1LDkgMTAsNCA4LDIgNSw1ICIvPg0KPC9zdmc+DQo=");
}

.sv_p_title .sv_panel_icon.sv_expanded::before {
  transform: rotate(180deg);
}

.sv_p_footer {
  padding-left: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
}

.sv_matrix_cell_detail_button {
  position: relative;
}

.sv_detail_panel_icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
}

.sv_detail_panel_icon::before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  display: block;
  transform: rotate(270deg);
  background-image: url("data:image/svg+xml,<?xml version='1.0' encoding='utf-8'?><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 15 15' style='enable-background:new 0 0 15 15;' xml:space='preserve'><style type='text/css'> .st0{fill:#FFFFFF;}</style><polygon class='st0' points='14,5.5 12.6,4.1 7.5,9.1 2.4,4.1 1,5.5 7.5,12 '/></svg>");
}

.sv_detail_panel_icon.sv_detail_expanded::before {
  transform: rotate(0deg);
}

.sv_matrix_empty_rows_section {
  text-align: center;
  vertical-align: middle;
}

.sv_matrix_empty_rows_text {
  padding: 20px;
}

.sv_q_file > input[type=file],
.sv_q_file > button {
  display: inline-block;
}

.sv_q_file_preview {
  display: inline-block;
  vertical-align: top;
  border: 1px solid lightgray;
  padding: 5px;
  margin-top: 10px;
}

.sv_q_file_preview > a {
  display: block;
  overflow: hidden;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sv_q_file_remove_button {
  line-height: normal;
}

.sv_q_file_remove {
  display: block;
  cursor: pointer;
}

.sv_q_m_cell_text {
  cursor: pointer;
}

.sv_q_dd_other {
  margin-top: 1em;
}

.sv_q_dd_other input {
  width: 100%;
}

.sv_q_dd_clean-button {
  margin: 0.15em 3em;
}

.sv_q_dd_clean-button-svg {
  width: 1em;
  height: 1em;
}

.sv_q_dd_control {
  display: flex;
  justify-content: space-between;
  appearance: none;
}

.sv_q_dd_value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.sv_q_dd_root {
  position: relative;
}

.sv_q_dd_select_wrapper::after {
  content: " ";
  display: block;
  background-image: url("data:image/svg+xml,<?xml version='1.0' encoding='utf-8'?><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'><style type='text/css'> .st0{fill:#404040;}</style><polygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 34px;
  height: 100%;
  position: absolute;
  inset-inline-end: 0;
  top: 0;
}

.sv_q_dd_filter-string-input {
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.sv_q_dropdown_clean-button {
  margin: auto 2em;
}

.sv_q_tagbox__placeholder {
  position: absolute;
  top: 0;
  inset-inline-start: 1em;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: start;
  cursor: text;
  pointer-events: none;
}

.sv_qstn .sv-q-col-1,
.sv-question .sv-q-col-1 {
  width: 100%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-col-2,
.sv-question .sv-q-col-2 {
  width: calc(50% - 1em);
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-col-3,
.sv-question .sv-q-col-3 {
  width: calc(33.33333% - 1em);
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-col-4,
.sv-question .sv-q-col-4 {
  width: calc(25% - 1em);
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-col-5,
.sv-question .sv-q-col-5 {
  width: calc(20% - 1em);
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-column-1 {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-column-2 {
  max-width: 50%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-column-3 {
  max-width: 33.33333%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-column-4 {
  max-width: 25%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv-q-column-5 {
  max-width: 20%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
  word-break: break-word;
}

.sv_qstn .sv_q_file_input {
  color: transparent;
}

.sv_qstn .sv_q_imgsel label > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.sv_qstn .sv_q_imgsel label > div > img,
.sv_qstn .sv_q_imgsel label > div > embed {
  display: block;
}

.sv_qstn table tr td .sv_q_m_cell_label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

f-panel {
  padding: 0.5em 1em;
  display: inline-block;
  line-height: 2em;
}

.sv_progress_bar > span {
  white-space: nowrap;
}

.sv-ranking {
  outline: none;
  user-select: none;
}

.sv-ranking-item {
  cursor: pointer;
  position: relative;
}

.sv-ranking-item:focus .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:hove:not(:focus) .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-question--disabled .sv-ranking-item:hover .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:focus {
  outline: none;
}

.sv-ranking-item:focus .sv-ranking-item__icon--focus {
  visibility: visible;
}

.sv-ranking-item:focus .sv-ranking-item__index {
  background: white;
  box-shadow: 0 0 0 2px var(--primary, #19b394);
}

.sv-ranking-item__content {
  display: flex;
  align-items: center;
  line-height: 1em;
  padding: 5px 0px;
  border-radius: 100px;
}

.sv-ranking-item__icon-container {
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(3 * var(--base-unit, 8px));
  flex-shrink: 0;
}
.sv-ranking-item__icon-container svg:nth-of-type(1) {
  height: 16px;
  width: 10px;
}
.sv-ranking-item__icon-container svg:nth-of-type(2) {
  height: 24px;
  width: 10px;
}

.sv-ranking-item__icon {
  visibility: hidden;
  top: 20px;
  fill: var(--primary, #19b394);
  position: absolute;
}

.sv-ranking-item__index {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: var(--primary-light, rgba(25, 179, 148, 0.1));
  color: var(--foreground);
  border-radius: 100%;
  border: 2px solid transparent;
  width: 40px;
  height: 40px;
  line-height: 1em;
}
.sv-ranking-item__index svg {
  fill: var(--foreground);
  width: 16px;
  height: 16px;
}

.sv-ranking-item__text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 calc(2 * var(--base-unit, 8px));
}

.sv-ranking-item__ghost {
  display: none;
  background-color: var(--background-dim, #f3f3f3);
  border-radius: 100px;
  width: 200px;
  height: 55px;
  z-index: 1;
  position: absolute;
  left: 25px;
}

[dir=rtl] .sv-ranking-item__ghost {
  left: initilal;
  right: 25px;
}

.sv-ranking-item--ghost .sv-ranking-item__ghost {
  display: block;
}

.sv-ranking-item--ghost .sv-ranking-item__content {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__content {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.sv-ranking--drag .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking--mobile .sv-ranking-item__icon--hover {
  visibility: visible;
  fill: #9f9f9f;
}

.sv-ranking--mobile.sv-ranking--drag .sv-ranking-item--ghost .sv-ranking-item__icon.sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking--mobile.sv-ranking-shortcut {
  max-width: 80%;
}

.sv-ranking--design-mode .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking--disabled {
  opacity: 0.8;
}

.sv-ranking-shortcut .sv-ranking-item__icon {
  fill: var(--primary, #19b394);
}

.sv_qstn .sv_q_select_column {
  display: inline-block;
  vertical-align: top;
  min-width: 10%;
}

.sv_qstn .sv_q_select_column > *:not(.sv_technical) {
  display: block;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_qstn .sv_q_select_column textarea {
  margin-left: 0;
  padding-left: 0;
  line-height: initial;
}

.sv_main .sv-hidden {
  display: none !important;
}

.sv_main .sv-visuallyhidden {
  position: absolute;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.sv_main .sv-progress {
  height: 0.19em;
  background-color: var(--header-background-color, #e7e7e7);
  position: relative;
}

.sv_main .sv-progress__bar {
  background-color: var(--main-color, #1ab394);
  height: 100%;
  position: relative;
}

.sv_main .sv-paneldynamic__progress-container {
  position: relative;
  display: inline-block;
  width: calc(100% - 250px);
  margin-top: 20px;
}

.sv_main .sv-paneldynamic__add-btn {
  float: right;
}

.sv_main .sv-paneldynamic__add-btn--list-mode {
  float: none;
  margin-top: 0;
}

.sv_main .sv-paneldynamic__remove-btn {
  margin-top: 1.25em;
}

.sv_main .sv-paneldynamic__remove-btn--right {
  margin-top: 0;
  margin-left: 1.25em;
}

.sv_main .sv-paneldynamic__prev-btn,
.sv_main .sv-paneldynamic__next-btn {
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  width: 0.7em;
  top: -0.28em;
  position: absolute;
}

.sv_main .sv-paneldynamic__prev-btn svg,
.sv_main .sv-paneldynamic__next-btn svg {
  width: 0.7em;
  height: 0.7em;
  display: block;
}

.sv_main .sv-paneldynamic__prev-btn {
  left: -1.3em;
  transform: rotate(90deg);
}

.sv_main .sv-paneldynamic__next-btn {
  right: -1.3em;
  transform: rotate(270deg);
}

.sv_main .sv-paneldynamic__prev-btn.sv-paneldynamic__prev-btn--disabled,
.sv_main .sv-paneldynamic__next-btn.sv-paneldynamic__next-btn--disabled {
  cursor: auto;
}

.sv_main .sv-paneldynamic__progress-text {
  font-weight: bold;
  font-size: 0.87em;
  margin-top: 0.69em;
  margin-left: 4em;
}

.sv_main .sv-boolean__switch {
  display: inline-block;
  box-sizing: border-box;
  width: 63px;
  height: 24px;
  margin-right: 17px;
  margin-left: 21px;
  padding: 2px 3px;
  vertical-align: middle;
  border-radius: 12px;
  cursor: pointer;
}

.sv_main .sv-boolean__thumb-ghost {
  display: inline-block;
}

.sv_main .sv-boolean__slider {
  display: inline-block;
  width: 20px;
  height: 20px;
  transition-duration: 0.4s;
  transition-property: margin-left;
  border: none;
  border-radius: 100%;
}

.sv_main .sv-boolean__label {
  vertical-align: middle;
  cursor: pointer;
}

.sv_main .sv-boolean--indeterminate .sv-boolean__slider {
  margin-left: calc(50% - 10px);
}

.sv_main .sv-boolean input:focus ~ .sv-boolean__switch {
  outline-width: 1px;
  outline-style: solid;
  outline-offset: 1px;
}

.sv_main .sv-boolean--checked .sv-boolean__slider {
  margin-left: calc(100% - 20px);
}

[dir=rtl] .sv-boolean__label {
  float: right;
}

[dir=rtl] .sv-boolean--indeterminate .sv-boolean__slider {
  margin-right: calc(50% - 0.625em);
}

[dir=rtl] .sv-boolean--checked .sv-boolean__slider {
  margin-right: calc(100% - 1.25em);
}

[dir=rtl] .sv-boolean__switch {
  float: right;
}

[style*="direction:rtl"] .sv-boolean__label {
  float: right;
}

[style*="direction:rtl"] .sv-boolean--indeterminate .sv-boolean__slider {
  margin-right: calc(50% - 0.625em);
}

[style*="direction:rtl"] .sv-boolean--checked .sv-boolean__slider {
  margin-right: calc(100% - 1.25em);
}

[style*="direction:rtl"] .sv-boolean__switch {
  float: right;
}

.sv_main .sjs_sp_container {
  position: relative;
  box-sizing: content-box;
}

.sv_main .sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sv_main .sjs_sp_controls > button {
  user-select: none;
}

.sv_main .sjs_sp_container > div > canvas:focus {
  outline: none;
}

.sv_main .sjs_sp_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.sv_main .sv-logo--left {
  display: inline-block;
  vertical-align: top;
  margin-right: 2em;
}

.sv_main .sv-logo--right {
  display: inline-block;
  vertical-align: top;
  margin-left: 2em;
}

.sv_main .sv-logo--top {
  display: block;
  width: 100%;
  text-align: center;
}

.sv_main .sv-logo--bottom {
  display: block;
  width: 100%;
  text-align: center;
}

.sv_main .sv_header__text {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: 100%;
}

.sv_main .sv-expand-action:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,<?xml version='1.0' encoding='utf-8'?><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'><style type='text/css'> .st0{fill:#404040;}</style><polygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  height: 10px;
  width: 12px;
  margin: auto 8px;
}

.sv_main .sv-expand-action--expanded:before {
  transform: rotate(180deg);
}

.sv_main .sv-action-bar {
  display: flex;
  position: relative;
  align-items: center;
  margin-left: auto;
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.sv_main .sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: #d6d6d6;
}

.sv_main .sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 16px;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
  overflow-x: hidden;
  white-space: nowrap;
  min-width: auto;
  font-weight: normal;
}

.sv_main .sv-action-bar-item__title {
  vertical-align: middle;
  white-space: nowrap;
}

.sv_main .sv-action-bar-item__title--with-icon {
  margin-left: 8px;
}

.sv_main .sv-action__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sv_main .sv-action__content > * {
  flex: 0 0 auto;
}

.sv_main .sv-action--hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.sv_main .sv-action-bar-item__icon svg {
  display: block;
}

.sv_main .sv-action-bar-item:active {
  opacity: 0.5;
}

.sv_main .sv-action-bar-item:focus {
  outline: none;
}

.sv_main .sv-title-actions {
  display: flex;
  align-items: center;
  width: 100%;
}

.sv_main .sv-title-actions__title {
  flex-wrap: wrap;
  max-width: 90%;
  min-width: 50%;
}

.sv_main .sv-title-actions__bar {
  min-width: 56px;
}

.sv_main .sv-title-actions .sv-title-actions__title {
  min-width: unset;
  max-width: unset;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.sv_main .sv-title-actions .sv-action-title-bar {
  min-width: unset;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.sv_main .sv_matrix_cell_actions .sv-action-bar {
  margin-left: 0;
  padding-left: 0;
}

.sv_main .sv_p_wrapper_in_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sv_main .sv_p_remove_btn_right {
  margin-left: 1em;
}

.sv_main .sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  height: 48px;
  overflow: auto;
}

.sv_main .sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 11px 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
}

.sv_main .sv-button-group__item:not(:first-of-type) {
  margin-left: -1px;
}

.sv_main .sv-button-group__item-icon {
  display: block;
  height: 24px;
}

.sv_main .sv-button-group__item--selected {
  font-weight: 600;
}

.sv_main .sv-button-group__item-decorator {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.sv_main .sv-button-group__item-icon + .sv-button-group__item-caption {
  margin-left: 8px;
}

.sv_main .sv-button-group__item-caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv_main .sv-button-group__item--disabled {
  cursor: default;
}

sv-popup {
  display: block;
  position: absolute;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  outline: none;
  z-index: 1500;
  height: 100vh;
}

.sv-dropdown-popup {
  height: 0;
}

.sv-popup__container {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  padding: 0;
}

.sv-popup__body-content {
  background-color: var(--background, #fff);
  border-radius: calc(0.5 * var(--base-unit, 8px));
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 100vw;
}

.sv-popup--modal .sv-list__filter {
  padding-top: 8px;
}

.sv-popup--modal .sv-list__filter-icon {
  top: 20px;
}

.sv-popup--overlay .sv-list__filter {
  padding-top: 8px;
}

.sv-popup--overlay .sv-list__filter-icon {
  top: 20px;
}

.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sv-popup--modal .sv-popup__container {
  position: static;
}

.sv-popup--modal .sv-popup__body-content {
  padding: calc(4 * var(--base-unit, 8px));
}

.sv-popup--overlay {
  width: 100%;
}

.sv-popup--overlay .sv-popup__container {
  background: rgba(144, 144, 144, 0.5);
  max-width: 100vw;
  max-height: calc(100vh - 1 * var(--base-unit, 8px));
  height: calc(100vh - 1 * var(--base-unit, 8px));
  width: 100%;
  padding-top: calc(2 * var(--base-unit, 8px));
  border: unset;
}

.sv-popup__shadow {
  width: 100%;
  height: 100%;
  border-radius: calc(1 * var(--base-unit, 8px));
}

.sv-popup--overlay .sv-popup__body-content {
  border-radius: calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) 0px 0px;
  background: var(--background, #fff);
  box-shadow: 0px calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) rgba(0, 0, 0, 0.1);
  padding: calc(3 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
  height: calc(100% - 1 * var(--base-unit, 8px));
  max-height: 100vh;
  max-width: 100vw;
}

.sv-popup--overlay .sv-popup__scrolling-content {
  height: calc(100% - 10 * var(--base-unit, 8px));
}

.sv-popup--overlay .sv-popup__body-footer {
  margin-top: calc(2 * var(--base-unit, 8px));
}

.sv-popup--overlay .sv-popup__body-footer .sv-action {
  width: 100%;
}

.sv-popup--overlay .sv-popup__body-footer .sv-action-bar {
  width: 100%;
}

.sv-popup--overlay .sv-popup__body-footer-item {
  width: 100%;
}

.sv-popup--overlay .sv-popup__button {
  background-color: var(--primary, #19b394);
  border: 2px solid var(--primary, #19b394);
  color: var(--primary-foreground, #fff);
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.sv-popup__scrolling-content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--background-dim, #f3f3f3);
}

.sv-popup__scrolling-content::-webkit-scrollbar-thumb {
  background: var(--primary-light, rgba(25, 179, 148, 0.1));
}

.sv-popup__content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-popup--show-pointer.sv-popup--top .sv-popup__pointer {
  transform: translate(calc(-1 * var(--base-unit, 8px))) rotate(180deg);
}

.sv-popup--show-pointer.sv-popup--bottom .sv-popup__pointer {
  transform: translate(calc(-1 * var(--base-unit, 8px)), calc(-1 * var(--base-unit, 8px)));
}

.sv-popup--show-pointer.sv-popup--right .sv-popup__container {
  transform: translate(calc(1 * var(--base-unit, 8px)));
}

.sv-popup--show-pointer.sv-popup--right .sv-popup__pointer {
  transform: translate(-12px, -4px) rotate(-90deg);
}

.sv-popup--show-pointer.sv-popup--left .sv-popup__container {
  transform: translate(calc(-1 * var(--base-unit, 8px)));
}

.sv-popup--show-pointer.sv-popup--left .sv-popup__pointer {
  transform: translate(-4px, -4px) rotate(90deg);
}

.sv-popup__pointer {
  display: block;
  position: absolute;
}

.sv-popup__pointer:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-left: calc(1 * var(--base-unit, 8px)) solid transparent;
  border-right: calc(1 * var(--base-unit, 8px)) solid transparent;
  border-bottom: calc(1 * var(--base-unit, 8px)) solid var(--background, #fff);
  align-self: center;
}

.sv-popup__body-header {
  font-family: var(--font-family, var(--sjs-default-font-family));
  font-size: calc(3 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
  font-style: normal;
  font-weight: 700;
  margin-bottom: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calc(4 * var(--base-unit, 8px));
}

.sv-popup__body-footer .sv-action-bar {
  gap: calc(1.5 * var(--base-unit, 8px));
}

.sv-popup__button {
  padding: calc(2 * var(--base-unit, 8px)) calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  font-family: var(--font-family, var(--sjs-default-font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  text-align: center;
  color: var(--primary, #19b394);
  border: none;
  outline: none;
}

.sv-popup__button:hover {
  box-shadow: 0 0 0 2px var(--primary, #19b394);
}

.sv-popup__button:disabled {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
  cursor: default;
}

.sv-popup__button:disabled:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.sv-popup__button.sv-popup__button--apply {
  background-color: var(--primary, #19b394);
  color: var(--primary-foreground, #fff);
}

.sv-popup__button.sv-popup__button--apply:disabled {
  background-color: var(--background-dim, #f3f3f3);
}

.sv-popup__button.sv-popup__button--danger {
  background-color: var(--sjs-special-red, #E50A3E);
  color: var(--primary-foreground, #fff);
}

.sv-list {
  padding: 0;
  margin: 0;
  background: var(--background, #fff);
  list-style-type: none;
  overflow-y: auto;
}

.sv-list__item--with-icon {
  padding-top: 12px;
  padding-bottom: 12px;
}

.sv-list__item {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-list__item-body {
  width: 100%;
  box-sizing: border-box;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  padding-top: calc(1 * var(--base-unit, 8px));
  padding-bottom: calc(1 * var(--base-unit, 8px));
  padding-inline-end: calc(8 * var(--base-unit, 8px));
  padding-inline-start: calc(2 * var(--base-unit, 8px));
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  gap: 16px;
}

.sv-list__item-icon {
  float: left;
  width: 24px;
  height: 24px;
}

.sv-list__item-icon svg {
  display: block;
}

.sv-list__item-icon use {
  fill: #909090;
}

.sv-list__item:hover .sv-list__item-body {
  background-color: var(--background-dim, #f3f3f3);
}

.sv-list__item.sv-list__item--focused .sv-list__item-body {
  background-color: var(--background-dim, #f3f3f3);
}

.sv-list__loading-indicator {
  pointer-events: none;
}

.sv-list__loading-indicator .sv-list__item-body {
  background-color: transparent;
}

.sv-list__item--selected .sv-list__item-icon use {
  fill: var(--primary-foreground, #fff);
}

.sv-list__item.sv-list__item--disabled {
  color: var(--foreground-light, #909090);
  cursor: default;
}

.sv-list__item span {
  white-space: nowrap;
}

.sv-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-list__filter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  background: var(--background, #fff);
  padding-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  line-height: 24px;
  padding-left: 56px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  outline: none;
  font-size: 1em;
  border: none;
}

.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: 12px;
  left: 16px;
}

.sv-list__filter-icon .sv-svg-icon {
  width: 24px;
  height: 24px;
}

.sv-list__empty-container {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-list__empty-text {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--foreground-light, #909090);
}

.sv-skeleton-element {
  min-height: 50px;
}