sv-brand-info {}

sv-brand-info, .sv-brand-info {
  z-index: 1;
  position: relative;
  margin-top: 1px;
}

.sv-brand-info {
  width: 100%;
  font-family: $font-family;
  text-align: center;
  color: #161616;
  background: white;
  padding: 32px 0;
  box-shadow: 0px -1px 0px #D6D6D6;

  a {
    color: #161616;
    text-decoration-line: underline;
  }
}

.sv-brand-info__text {
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
  color: #161616;
}

.sv-brand-info__logo {
  img {
    width: 118px;
  }

  display: inline-block;
}

.sv-brand-info__terms {
  font-weight: 400;
  font-size: calcFontSize(0.75);
  line-height: calcLineHeight(1);

  a {
    color: #909090;
  }
}