:root {
  --primary: #91278f;
  --sjs-general-backcolor-dim: #fefafe;
  --font-family: "IBM Plex Sans Devanagari", sans-serif !important;
}

body {
  margin: 0px;
  background-color: #fefafe;
}

a {
  text-decoration: none;
}

.sd-body .sd-body__page {
  min-width: unset !important;
  overflow: hidden !important;
}

.ql-container {
  min-height: 100px;
}

.svc-creator__content-holder{
  min-height: calc( 100vh - 400px ) !important;
}

.svc-creator{
  max-height: calc( 100vh - 160px ) !important;
}
 
::-webkit-scrollbar{
  width: 8px !important;
  height: 8px !important;
}
::-webkit-scrollbar-track {
  background: #F5E6F5;
  border-radius: 6px
}
::-webkit-scrollbar-thumb {
  background: #DFB0DF;
  border-radius: 6px
}
 